import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getSeasonalOffers = createAsyncThunk('seasonalOffers/getSeasonalOffers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/seasonal-offers`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSeasonalOffersCount = createAsyncThunk('seasonalOffers/getSeasonalOffersCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/seasonal-offers/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleSeasonalOffer = createAsyncThunk('seasonalOffers/getSingleSeasonalOffer', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/seasonal-offers/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        offer : response.data
    }
})

export const reorderSeasonalOffers = createAsyncThunk('seasonalOffers/reorderSeasonalOffer', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/seasonal-offers-reorder`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editSeasonalOffer = createAsyncThunk('seasonalOffers/editSeasonalOffer', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/seasonal-offers/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteFile = createAsyncThunk('seasonalOffers/deleteFile', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postImage = createAsyncThunk('seasonalOffers/postImage', async ({file, id}, {dispatch}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'seasonal-offer')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }
    await dispatch(getSingleSeasonalOffer(id))

    return response
})

export const SeasonalOffersSlice = createSlice({
    name: 'SeasonalOffers',
    initialState: {
        data: [],
        count: 1,
        params: {},
        page: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSeasonalOffers.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSeasonalOffersCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleSeasonalOffer.fulfilled, (state, action) => {
            state.offer = action.payload.offer
        })
    }
})

export default SeasonalOffersSlice.reducer