import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios"

export const getGlobalSettings = createAsyncThunk('accommodationUnits/getGlobalSettings', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/global-settings`)

    if (response.status !== 200) {
        return null
    }

    return response.data
})

export const editGlobalSettings = createAsyncThunk('accommodationUnits/editGlobalSettings', async data => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/global-settings`, data)

    if (response.status !== 200) {
        return null
    }

    return response.data
})

export const getAccommodationUnits = createAsyncThunk('accommodationUnits/getAccommodationUnits', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-units`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const deleteAccommodationUnit = createAsyncThunk('accommodationUnits/deleteAccommodationUnit', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/accommodation-units/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleAccommodationUnit = createAsyncThunk('accommodationUnits/getSingleAccommodationUnit', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-units/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        unit : response.data
    }
})

export const postAccommodationUnit = createAsyncThunk('accommodationUnits/postAccommodationUnit', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/accommodation-units`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editAccommodationUnit = createAsyncThunk('accommodationUnits/editAccommodationUnit', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/accommodation-units/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAccommodationUnitsCount = createAsyncThunk('accommodationUnits/getAccommodationUnitsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-units/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getPrices = createAsyncThunk('accommodationUnits/getPrices', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/prices`, {
        params : {
            accommodation_unit : id
        }
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editPrice = createAsyncThunk('accommodationUnits/editPrice', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/prices/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postPrice = createAsyncThunk('accommodationUnits/postPrice', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/prices`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deletePrice = createAsyncThunk('accommodationUnits/deletePrice', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/prices/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteGalleryImage = createAsyncThunk('accommodationUnits/deleteGalleryImage', async id => {
    let response = await axios.delete(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`)

    if (response.status !== 200) {
        return null
    }

    response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${response.data.image.id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editGalleryImage = createAsyncThunk('accommodationUnits/editGalleryImage', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFile = createAsyncThunk('accommodationUnits/postFile', async ({file, accommodation_unit_id}) => {
    const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/gallery-images`, {
        active: file.active ? file.active : false,
        accommodation_unit: accommodation_unit_id
    })
    delete file.active

    if (response1.status !== 200) {
        return null
    }

    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'gallery-image')
    formData.append('refId', response1.data.id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postPrimaryFile = createAsyncThunk('accommodationUnits/postPrimaryFile', async ({file, id}, {dispatch}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'accommodation-unit')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }
    await dispatch(getSingleAccommodationUnit(id))

    return response
})

export const accommodationSlice = createSlice({
    name: 'invoices',
    initialState: {
        data: [],
        count: 1,
        params: {},
        unit: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAccommodationUnits.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getAccommodationUnitsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleAccommodationUnit.fulfilled, (state, action) => {
            state.unit = action.payload.unit
        })
    }
})

export default accommodationSlice.reducer
