import React, {useState} from 'react'
import logo from '../../../../assets/images/logo/logo.png'
import {useTranslation} from "react-i18next"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
//import { FaCaretDown } from 'react-icons/fa'

function Navigation() {
  //const dispatch = useDispatch()
  const { t } = useTranslation()

  //const [dropdownOpen, setDropdownOpen] = useState(false)
  //const [dropdownItemFocused, setDropdownItemFocused] = useState(false)
  //const [objects, setObjects] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  // const mouseEnterEvent = () => {
  //   //setDropdownOpen(true)
  // }

  // const fetchDataOnMount = async () => {
  //   const response = await dispatch(getAccommodationObjects())
  //   if (response.error) {
  //     toast(ts => (
  //         <Toast t={ts} title={t("public.accommodation.fetch.data.error.title")} type={"danger"} message={t("public.accommodation.fetch.data.error.description")} />
  //     ))
  //   } else {
  //     //setObjects(response.payload.data)
  //   }
  // }
  //
  // useEffect(() => {
  //   fetchDataOnMount()
  // }, [])

  return (
      <>
        <Navbar light expand='lg' className="frontend-nav">
          <div className="d-flex d-lg-block flex-column align-items-center mx-auto">
            <NavbarBrand href="/uvod" className="me-auto d-block d-lg-none m-0 mb-1 mb-md-2">
              <img src={logo} alt="Biofarma Kaška" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 6l16 0"></path>
                <path d="M4 12l16 0"></path>
                <path d="M4 18l16 0"></path>
              </svg>
            </NavbarToggler>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="mx-auto">
              <NavItem>
                <NavLink href="/o-nas" className={`nav-link ${window.location.href.includes("/o-nas") ? "active" : null}`}>O nás</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/ubytovanie" className={`nav-link ${window.location.href.includes("/ubytovanie") ? "active" : null}`}>Ubytovanie</NavLink>
              </NavItem>
              {/*<NavItem className='d-flex justify-content-center'>*/}
              {/*  <ButtonDropdown tag='a' href="/ubytovanie" className={'nav-link'} isOpen={dropdownOpen} toggle={() => {  }} onMouseEnter={() => { mouseEnterEvent() }}>*/}
              {/*    <DropdownToggle className={`${window.location.href.includes("/ubytovanie") ? "active" : null} nav-item-dropdown kaska-dropdown`} color="0">*/}
              {/*      {t("public.menu.accommodation")} <FaCaretDown />*/}
              {/*    </DropdownToggle>*/}
              {/*    <DropdownMenu className="dropdown-menu-class" onMouseEnter={() => { setDropdownItemFocused(true) }} onMouseLeave={() => { setDropdownItemFocused(!dropdownItemFocused); setDropdownOpen(false) }}>*/}
              {/*      {objects && objects.length > 0 ? objects.map((object, index) => (*/}
              {/*          <DropdownItem className={`${window.location.href.includes(`/ubytovanie/${object?.slug}`) ? "nav-item-dropdown-item-active" : "nav-item-dropdown-item"}`} key={index} href={`/ubytovanie/${object?.slug}`} tag='a'>{object?.title}</DropdownItem>*/}
              {/*      )) : null}*/}
              {/*    </DropdownMenu>*/}
              {/*  </ButtonDropdown>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink href="/aktivity" className={`nav-link ${window.location.href.includes("/u-nas-zazijete") ? "active" : null}`}>Doplnkové služby</NavLink>
              </NavItem>
              <NavbarBrand href="/uvod" className="d-none d-lg-block">
                <img src={logo} alt="Biofarma Kaška" />
              </NavbarBrand>
              <NavItem>
                <NavLink href="/vylety" className={`nav-link ${window.location.href.includes("/vylety") ? "active" : null}`}>{t("public.menu.trips")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/galeria" className={`nav-link ${window.location.href.includes("/galeria") ? "active" : null}`}>{t("public.menu.gallery")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/nasa-produkcia" className={`nav-link ${window.location.href.includes("/produkty") ? "active" : null}`}>Produkty</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/kontakt" className={`nav-link ${window.location.href.includes("/kontakt") ? "active" : null}`}>{t("public.menu.contact")}</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </>
  )
}

export default Navigation
