import React, {useEffect, useState} from 'react'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import {useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"
import {getActivityPrices, getCMSPage} from "./store"
import toast from "react-hot-toast"
import Toast from "../../components/toast/Toast"
import {renderContentByMark, renderImageByMark, renderTitleByMark} from "./components/SharedFunctions"

const PriceList = () => {
  const dispatch = useDispatch()
  const [mountLoading, setMountLoading] = useState(false)
  const [prices, setPrices] = useState([])
  const [page, setPage] = useState([])
  const { t } = useTranslation()

  const fetchDataOnMount = async () => {
    setMountLoading(true)
    const response = await dispatch(getCMSPage("Cenník"))
    const response2 = await dispatch(getActivityPrices({
      _sort: "createdAt:asc",
      active : true
    }))
    if (response.error || response2.error) {
      toast(ts => (
          <Toast t={ts} title={t("cms.pages.fetch.data.error.title")} type={"danger"} message={t("cms.pages.fetch.data.error.description")} />
      ))
    } else {
      setPage(response.payload?.data)
      setPrices(response2.payload?.data)
    }
    setMountLoading(false)
  }

  useEffect(() => {
    fetchDataOnMount()
  }, [])

  return (
    <>
      <Navbar />

        {!mountLoading ? <>
          <div className="page-content">
            <div className="container" data-aos="fade-up">
              <div className="breadcrumb-wrapper">
                <section className="breadcrumb" style={{ backgroundImage: `url(${renderImageByMark(page, "{prices-description}")})` }}>
                  <div className="row">
                    <div className="col-lg-12 d-flex flex-column align-items-center breadcrumb-content">
                      <h1>{renderTitleByMark(page, "{prices-description}")}</h1>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <section className="price-list" data-aos="fade-up">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 price-list-title">
                    <h6>{renderContentByMark(page, "{prices-description}")}</h6>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="table-responsive">
                      <table className="table">
                        {prices.length > 0 ? prices.map((price, index) => (
                            <tr key={index}>
                              <td>{price.title}</td>
                              <td>{price.description}</td>
                            </tr>
                        )) : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>

          <Footer />
        </> : null}
    </>
  )
}

export default PriceList