import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getImageBlogArticles = createAsyncThunk('blogArticles/getImageBlogArticles', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/image-blog-articles`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getImageBlogArticlesCount = createAsyncThunk('blogArticles/getImageBlogArticlesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/image-blog-articles/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteImageBlogArticle = createAsyncThunk('blogArticles/deleteImageBlogArticle', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/image-blog-articles/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleImageBlogArticle = createAsyncThunk('blogArticles/getSingleImageBlogArticle', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/image-blog-articles/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        article : response.data
    }
})

export const postImageBlogArticle = createAsyncThunk('blogArticles/postImageBlogArticle', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/image-blog-articles`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editImageBlogArticle = createAsyncThunk('blogArticles/editImageBlogArticle', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/image-blog-articles/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteImage = createAsyncThunk('blogArticles/deleteImage', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postGalleryImage = createAsyncThunk('blogArticles/postGalleryImage', async ({file, id}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'image-blog-article')
    formData.append('refId', id)
    formData.append('field', "files")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postLogoImage = createAsyncThunk('blogArticles/postLogoImage', async ({file, id}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'image-blog-article')
    formData.append('refId', id)
    formData.append('field', "logo")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const imageBlogArticlesSlice = createSlice({
    name: 'imageBlogArticles',
    initialState: {
        data: [],
        count: 1,
        params: {},
        article: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getImageBlogArticles.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getImageBlogArticlesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleImageBlogArticle.fulfilled, (state, action) => {
            state.article = action.payload.article
        })
    }
})

export default imageBlogArticlesSlice.reducer