import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getBlogCategories = createAsyncThunk('blogCategories/getBlogCategories', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-categories`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getBlogCategoriesCount = createAsyncThunk('blogCategories/getBlogCategoriesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-categories/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteBlogCategory = createAsyncThunk('blogCategories/deleteBlogCategory', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/blog-categories/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleBlogCategory = createAsyncThunk('blogCategories/getSingleBlogCategory', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-categories/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        category : response.data
    }
})

export const postBlogCategory = createAsyncThunk('blogCategories/postBlogCategory', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/blog-categories`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editBlogCategory = createAsyncThunk('blogCategories/editBlogCategory', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/blog-categories/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const blogCategoriesSlice = createSlice({
    name: 'blogCategories',
    initialState: {
        data: [],
        count: 1,
        params: {},
        category: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBlogCategories.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getBlogCategoriesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        })
    }
})

export default blogCategoriesSlice.reducer