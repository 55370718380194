// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  }
]

export const initialRootAbility = [
  {
    action: "manage",
    subject: "all"
  }
]

export const _ = undefined
