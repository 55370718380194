import { lazy } from 'react'

const DashboardView = lazy(() => import('../../../../kaska/modules/dashboard/view'))
const DashboardAnalytics = lazy(() => import('../../../../views/dashboard/analytics'))

const AccommodationList = lazy(() => import('../../../../kaska/modules/accommodation-units/list'))
const AccommodationAdd = lazy(() => import('../../../../kaska/modules/accommodation-units/add'))
const AccommodationEdit = lazy(() => import('../../../../kaska/modules/accommodation-units/edit'))

const AccommodationObjectList = lazy(() => import('../../../../kaska/modules/accommodation-objects/list'))
const AccommodationObjectAdd = lazy(() => import('../../../../kaska/modules/accommodation-objects/add'))
const AccommodationObjectEdit = lazy(() => import('../../../../kaska/modules/accommodation-objects/edit'))

const ReservationList = lazy(() => import('../../../../kaska/modules/reservations/list'))
const ReservationAdd = lazy(() => import('../../../../kaska/modules/reservations/add'))
const ReservationEdit = lazy(() => import('../../../../kaska/modules/reservations/edit'))

const CommonServiceAdd = lazy(() => import('../../../../kaska/modules/common-services/add'))
const CommonServiceEdit = lazy(() => import('../../../../kaska/modules/common-services/edit'))
const CommonServiceList = lazy(() => import('../../../../kaska/modules/common-services/list'))

const ServiceAdd = lazy(() => import('../../../../kaska/modules/services/add'))
const ServiceEdit = lazy(() => import('../../../../kaska/modules/services/edit'))
const ServiceList = lazy(() => import('../../../../kaska/modules/services/list'))

const DocumentList = lazy(() => import('../../../../kaska/modules/documents/list'))
const DocumentAdd = lazy(() => import('../../../../kaska/modules/documents/add'))
const DocumentEdit = lazy(() => import('../../../../kaska/modules/documents/edit'))

const CouponAdd = lazy(() => import('../../../../kaska/modules/coupons/add'))
const CouponEdit = lazy(() => import('../../../../kaska/modules/coupons/edit'))
const CouponList = lazy(() => import('../../../../kaska/modules/coupons/list'))


const BlogArticlesAdd = lazy(() => import('../../../../kaska/modules/image-blog-articles/add'))
const BlogArticlesEdit = lazy(() => import('../../../../kaska/modules/image-blog-articles/edit'))
const BlogArticlesList = lazy(() => import('../../../../kaska/modules/image-blog-articles/list'))


// const HallsAdd = lazy(() => import('../../../../kaska/modules/halls/add'))
// const HallsEdit = lazy(() => import('../../../../kaska/modules/halls/edit'))
// const HallsList = lazy(() => import('../../../../kaska/modules/halls/list'))
//const DemandsList = lazy(() => import('../../../../kaska/modules/demands/list'))
//const DemandsDetail = lazy(() => import('../../../../kaska/modules/demands/detail'))

const SurveyAnswersList = lazy(() => import('../../../../kaska/modules/survey/survey-answers/list'))
const SurveyRequestsList = lazy(() => import('../../../../kaska/modules/survey/survey-requests/list'))
const SurveyRequestsDetail = lazy(() => import('../../../../kaska/modules/survey/survey-requests/detail'))
const SurveyQuestionsAdd = lazy(() => import('../../../../kaska/modules/survey/survey-questions/add'))
const SurveyQuestionsEdit = lazy(() => import('../../../../kaska/modules/survey/survey-questions/edit'))
const SurveyQuestionsList = lazy(() => import('../../../../kaska/modules/survey/survey-questions/list'))

const CMSPagesList = lazy(() => import('../../../../kaska/modules/cms-pages/list'))
const CMSPagesEdit = lazy(() => import('../../../../kaska/modules/cms-pages/edit'))

const SeasonalOffersList = lazy(() => import('../../../../kaska/modules/seasonal-offers/list'))
const SeasonalOffersEdit = lazy(() => import('../../../../kaska/modules/seasonal-offers/edit'))

const GalleriesList = lazy(() => import('../../../../kaska/modules/galleries/list'))
const GalleriesEdit = lazy(() => import('../../../../kaska/modules/galleries/edit'))

const ActivitiesAdd = lazy(() => import('../../../../kaska/modules/activities/add'))
const ActivitiesEdit = lazy(() => import('../../../../kaska/modules/activities/edit'))
const ActivitiesList = lazy(() => import('../../../../kaska/modules/activities/list'))

const ActivityPricesAdd = lazy(() => import('../../../../kaska/modules/activity-prices/add'))
const ActivityPricesEdit = lazy(() => import('../../../../kaska/modules/activity-prices/edit'))
const ActivityPricesList = lazy(() => import('../../../../kaska/modules/activity-prices/list'))

const AdminFormsEdit = lazy(() => import('../../../../kaska/modules/admin-forms/edit'))
const AdminFormsList = lazy(() => import('../../../../kaska/modules/admin-forms/list'))

const AutomatedEmailsEdit = lazy(() => import('../../../../kaska/modules/editable-automated-emails/edit'))
const AutomatedEmailsList = lazy(() => import('../../../../kaska/modules/editable-automated-emails/list'))

const ContactsEdit = lazy(() => import('../../../../kaska/modules/contacts/edit'))
const ContactsList = lazy(() => import('../../../../kaska/modules/contacts/list'))

const AdminRoutes = [
  {
    path: '/dashboard/analytics',
    element: <DashboardAnalytics />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/dashboard',
        element: <DashboardView />,
      meta: {
      action: 'manage',
        resource: 'all',
        roles: ["admin"]
    }
  },
  {
    path: '/contacts',
    element: <ContactsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/contacts/edit/:id',
    element: <ContactsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-units',
    element: <AccommodationList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-units/add',
    element: <AccommodationAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-units/edit/:id',
    element: <AccommodationEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-objects',
    element: <AccommodationObjectList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-objects/add',
    element: <AccommodationObjectAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/accommodation-objects/edit/:id',
    element: <AccommodationObjectEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/admin-forms',
    element: <AdminFormsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/admin-forms/edit/:id',
    element: <AdminFormsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/automated-emails',
    element: <AutomatedEmailsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/automated-emails/edit/:id',
    element: <AutomatedEmailsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/reservations',
    element: <ReservationList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/reservations/edit/:id',
    element: <ReservationEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/reservations/add',
    element: <ReservationAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/common-services',
    element: <CommonServiceList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/common-services/edit/:id',
    element: <CommonServiceEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/common-services/add',
    element: <CommonServiceAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/services',
    element: <ServiceList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/services/edit/:id',
    element: <ServiceEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/services/add',
    element: <ServiceAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/documents',
    element: <DocumentList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/coupons',
    element: <CouponList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/documents/edit/:id',
    element: <DocumentEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/coupons/add',
    element: <CouponAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/documents/add',
    element: <DocumentAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/blog-articles',
    element: <BlogArticlesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/blog-articles/add',
    element: <BlogArticlesAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/blog-articles/edit/:id',
    element: <BlogArticlesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  // {
  //   path: '/halls',
  //   element: <HallsList />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["admin"]
  //   }
  // },
  // {
  //   path: '/halls/add',
  //   element: <HallsAdd />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["admin"]
  //   }
  // },
  // {
  //   path: '/halls/edit/:id',
  //   element: <HallsEdit />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["admin"]
  //   }
  // },
  // {
  //   path: '/demands',
  //   element: <DemandsList />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["admin"]
  //   }
  // },
  // {
  //   path: '/demands/:id',
  //   element: <DemandsDetail />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["admin"]
  //   }
  // },
  {
    path: '/survey-questions',
    element: <SurveyQuestionsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/survey-questions/edit/:id',
    element: <SurveyQuestionsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/survey-questions/add',
    element: <SurveyQuestionsAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/survey-answers',
    element: <SurveyAnswersList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/survey-requests',
    element: <SurveyRequestsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/survey-requests/:id',
    element: <SurveyRequestsDetail />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/cms-pages',
    element: <CMSPagesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/cms-pages/edit/:id',
    element: <CMSPagesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/galleries',
    element: <GalleriesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/galleries/edit/:id',
    element: <GalleriesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/seasonal-offers',
    element: <SeasonalOffersList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/seasonal-offers/edit/:id',
    element: <SeasonalOffersEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activities',
    element: <ActivitiesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activities/add',
    element: <ActivitiesAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activities/edit/:id',
    element: <ActivitiesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activity-prices',
    element: <ActivityPricesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activity-prices/add',
    element: <ActivityPricesAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/activity-prices/edit/:id',
    element: <ActivityPricesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  }
]

export default AdminRoutes
