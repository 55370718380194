//import { lazy } from 'react'

//const DashboardEcommerce = lazy(() => import('../../../../views/dashboard/ecommerce'))

// eslint-disable-next-line array-bracket-newline
const UserRoutes = [
  // {
  //   path: '/dashboard/ecommerce',
  //   element: <DashboardEcommerce />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'all',
  //     roles: ["authenticated"]
  //   }
  // }
// eslint-disable-next-line array-bracket-newline
]

export default UserRoutes
