import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"


export const getServices = createAsyncThunk('services/getServices', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getServicesCount = createAsyncThunk('services/getServicesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleService = createAsyncThunk('services/getSingleService', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        service : response.data
    }
})

export const deleteService = createAsyncThunk('services/deleteService', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/services/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postService = createAsyncThunk('services/postService', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/services`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editService = createAsyncThunk('services/editService', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/services/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getServiceSlots = createAsyncThunk('services/getServiceSlots', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/service-slots`, {
        params : {
            service : id
        }
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editServiceSlot = createAsyncThunk('services/editServiceSlot', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/service-slots/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postServiceSlot = createAsyncThunk('services/postServiceSlot', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/service-slots`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteServiceSlot = createAsyncThunk('services/deleteServiceSlot', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/service-slots/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        data: [],
        count: 1,
        params: {},
        service: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getServices.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getServicesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleService.fulfilled, (state, action) => {
            state.service = action.payload.service
        })
    }
})

export default servicesSlice.reducer