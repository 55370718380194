// ** React Imports
import { lazy } from 'react'
import NotAllowed from '../../../../kaska/pages/misc/NotAllowed'
import NotAuthorized from '../../../../kaska/pages/misc/NotAuthorized'

const Login = lazy(() => import('../../../../kaska/pages/authentication/login/Login'))
//const Register = lazy(() => import('../../../../kaska/pages/authentication/register/Register'))
const ForgotPassword = lazy(() => import('../../../../kaska/pages/authentication/forgot-password/ForgotPassword'))
const ResetPassword = lazy(() => import('../../../../kaska/pages/authentication/reset-password/ResetPassword'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  // {
  //   path: '/register',
  //   element: <Register />,
  //   meta: {
  //     layout: 'blank',
  //     publicRoute: true,
  //     restricted: true
  //   }
  // },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/not-allowed',
    element: <NotAllowed />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/not-authorized',
    element: <NotAuthorized />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
