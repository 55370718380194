import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const forgotPassword = createAsyncThunk('resetPassword/forgotPassword', async email  => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
        email
    })

    //console.log(response)
    if (response.status !== 200) {
        return null
    }
    return response
})

export const resetPasswordWithCode = createAsyncThunk('resetPassword/resetPasswordWithCode', async ({code, password, passwordConfirmation})  => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        code,
        password,
        passwordConfirmation
    })

    if (response.status !== 200) {
        return null
    }
    return response
})


export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: {

    },
    reducers: {}
})


export default resetPasswordSlice.reducer
