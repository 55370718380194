import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getCoupons = createAsyncThunk('coupons/getCoupons', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/coupons`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleCoupon = createAsyncThunk('coupons/getSingleCoupon', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/coupons/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response.data
})

export const deleteCoupon = createAsyncThunk('coupons/deleteCoupon', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/coupons/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postCoupon = createAsyncThunk('coupons/postCoupon', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/coupons`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editCoupon = createAsyncThunk('coupons/editCoupon', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/coupons/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getCouponsCount = createAsyncThunk('coupons/getCouponsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/coupons/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const reservationSlice = createSlice({
    name: 'coupons',
    initialState: {
        data: [],
        count: 1,
        params: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCoupons.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getCouponsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        })
    }
})

export default reservationSlice.reducer