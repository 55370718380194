import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getActivities = createAsyncThunk('activities/getActivities', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activities`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getActivitiesCount = createAsyncThunk('activities/getActivitiesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activities/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteActivity = createAsyncThunk('activities/deleteActivity', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/activities/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleActivity = createAsyncThunk('activities/getSingleActivity', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activities/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        activity : response.data
    }
})

export const postActivity = createAsyncThunk('activities/postActivity', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/activities`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editActivity = createAsyncThunk('activities/editActivity', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/activities/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteImage = createAsyncThunk('activities/deleteImage', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postImage = createAsyncThunk('activities/postImage', async ({file, id}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'activities')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteGalleryImage = createAsyncThunk('activities/deleteGalleryImage', async id => {
    let response = await axios.delete(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`)

    if (response.status !== 200) {
        return null
    }

    response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${response.data.image.id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editGalleryImage = createAsyncThunk('activities/editGalleryImage', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postGalleryImage = createAsyncThunk('activities/postFile', async ({file, activity_id}) => {
    const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/gallery-images`, {
        active: file.active ? file.active : false,
        activity: activity_id
    })
    delete file.active

    if (response1.status !== 200) {
        return null
    }

    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'gallery-image')
    formData.append('refId', response1.data.id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        data: [],
        count: 1,
        params: {},
        activity: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getActivities.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getActivitiesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleActivity.fulfilled, (state, action) => {
            state.activity = action.payload.activity
        })
    }
})

export default activitiesSlice.reducer
