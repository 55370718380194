import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getGalleries = createAsyncThunk('galleries/getGalleries', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/galleries`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleGallery = createAsyncThunk('galleries/getSingleGallery', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/galleries/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        gallery : response.data
    }
})

export const postGallery = createAsyncThunk('galleries/postGallery', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/galleries`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editGallery = createAsyncThunk('galleries/editGallery', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/galleries/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getGalleriesCount = createAsyncThunk('galleries/getGalleriesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/galleries/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteGalleryImage = createAsyncThunk('galleries/deleteGalleryImage', async id => {
    let response = await axios.delete(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`)

    if (response.status !== 200) {
        return null
    }

    response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${response.data.image.id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editGalleryImage = createAsyncThunk('galleries/editGalleryImage', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/gallery-images/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFile = createAsyncThunk('galleries/postFile', async ({file, gallery_id}) => {
    const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/gallery-images`, {
        active: file.active ? file.active : false,
        gallery: gallery_id
    })
    delete file.active

    if (response1.status !== 200) {
        return null
    }

    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'gallery-image')
    formData.append('refId', response1.data.id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const gallerySlice = createSlice({
    name: 'invoices',
    initialState: {
        data: [],
        count: 1,
        params: {},
        gallery: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getGalleries.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getGalleriesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleGallery.fulfilled, (state, action) => {
            state.gallery = action.payload.gallery
        })
    }
})

export default gallerySlice.reducer