// ** JWT Service Import
import JwtService from './jwtService'

// ** Export Service as useJwt
const useJwt = (jwtOverrideConfig) => {
  const jwt = new JwtService(jwtOverrideConfig)

  return {
    jwt
  }
}

// TODO set endpoints
const { jwt } = useJwt({
    loginEndpoint: `${process.env.REACT_APP_API_URL}/auth/local`,
    registerEndpoint: `${process.env.REACT_APP_API_URL}/auth/local/register`,
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
  
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',
  
    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
})

export default jwt
