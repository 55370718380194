import {createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"

export const getContacts = createAsyncThunk('contact/getContacts', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/contacts`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getActivityPrices = createAsyncThunk('cmsPages/getActivityPrices', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activity-prices`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getActivities = createAsyncThunk('cmsPages/getActivities', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activities`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getCMSPage = createAsyncThunk('cmsPages/getCMSPage', async page => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/text-blocks`, {
        params : {
            page
        }
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getGalleryBySlug = createAsyncThunk('gallery/getGalleryBySlug', async slug => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/galleries`, {
        params : {
            slug
        }
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getGalleryImagesOfUnit = createAsyncThunk('gallery/getGalleryImagesOfUnit', async unit_id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/gallery-images`, {
        params : {
            accommodation_unit : unit_id
        }
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAccommodationObjects = createAsyncThunk('gallery/getAccommodationObjects', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-objects`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAccommodationUnits = createAsyncThunk('gallery/getAccommodationUnits', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-units`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})
