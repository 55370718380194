// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

import { useEffect, useState } from 'react'
import { getLoggedUserRole } from '../utility/Utils'

const CustomFooter = () => {
  return <p className='mb-0'>@ Biofarma Kaška {new Date().getFullYear()} All right reserved.</p>
}

const VerticalLayout = props => {
  const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  const canAccessByRole = (roles, role) => {
    return roles.includes(role)
  }

  const checkForVisibleChild = (arr, role) => {
    return arr.some(i => {
      if (i.children) {
        return checkForVisibleChild(i.children, role)
      } else {
        return canAccessByRole(i.roles, role)
      }
    })
  }
  
  const canViewMenuGroup = (item, role) => {
    const hasAnyVisibleChild = item.children && checkForVisibleChild(item.children, role)
  
    if (!(item.roles && item.roles.length > 0)) {
      return hasAnyVisibleChild
    }
    return canAccessByRole(item.roles, role) && hasAnyVisibleChild
  }
  
  const canViewMenuItem = (item, role) => {
    return canAccessByRole(item.roles, role)
  }

  useEffect(() => {
    if (navigation && navigation.length > 0) {
      const role = getLoggedUserRole()
      let filtered = navigation.map((item) => {
        if (item.children) {
          return canViewMenuGroup(item, role) && item
        }
        return canViewMenuItem(item, role) && item
      })

      filtered = filtered.filter(element => typeof element === 'object' && element !== null)

      setMenuData(filtered)
    } else {
      setMenuData(navigation)
    }

  }, [navigation])

  return (
    <Layout 
      menuData={menuData} 
      footer={<CustomFooter />}
      // menu={props => <CustomMenu {...props} />} 
      // navbar={props => <KaskaNavbar {...props} />}
      {...props}
    >
      {/* {props.children} */}
      <Outlet />
    </Layout>
    // <Layout menuData={navigation} {...props}>
    //   <Outlet />
    // </Layout>
  )
}

export default VerticalLayout
