import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getContacts = createAsyncThunk('contacts/getContacts', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/contacts`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleContact = createAsyncThunk('contacts/getSingleContact', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/contacts/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        contact : response.data
    }
})

export const editContact = createAsyncThunk('contacts/editContact', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/contacts/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState: {
        data: [],
        params: {},
        contact: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getContacts.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSingleContact.fulfilled, (state, action) => {
            state.contact = action.payload.contact
        })
    }
})

export default contactsSlice.reducer
