import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios"


export const getAccommodationObjects = createAsyncThunk('accommodationUnits/getAccommodationObjects', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-objects`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const deleteAccommodationObject = createAsyncThunk('accommodationUnits/deleteAccommodationObject', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/accommodation-objects/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleAccommodationObject = createAsyncThunk('accommodationUnits/getSingleAccommodationObject', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-objects/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        object : response.data
    }
})

export const postAccommodationObject = createAsyncThunk('accommodationUnits/postAccommodationObject', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/accommodation-objects`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editAccommodationObject = createAsyncThunk('accommodationUnits/editAccommodationObject', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/accommodation-objects/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAccommodationObjectsCount = createAsyncThunk('accommodationObjects/getAccommodationObjectsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-objects/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const postImage = createAsyncThunk('accommodationObjects/postImage', async ({file, id}, {dispatch}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'accommodation-object')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }
    await dispatch(getSingleAccommodationObject(id))

    return response
})

export const getAccommodationUnits = createAsyncThunk('accommodationObjects/getAccommodationUnits', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/accommodation-units`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const accommodationObjectsSlice = createSlice({
    name: 'accommodationObjects',
    initialState: {
        data: [],
        count: 1,
        params: {},
        object: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAccommodationObjects.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getAccommodationObjectsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleAccommodationObject.fulfilled, (state, action) => {
            state.object = action.payload.object
        })
    }
})

export default accommodationObjectsSlice.reducer