import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"


export const getDocuments = createAsyncThunk('documents/getDocuments', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getDocumentsCount = createAsyncThunk('documents/getDocumentsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleDocument = createAsyncThunk('documents/getSingleDocument', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        document : response.data
    }
})

export const deleteDocument = createAsyncThunk('documents/deleteDocument', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/documents/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postDocument = createAsyncThunk('documents/postDocument', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editDocument = createAsyncThunk('documents/editDocument', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFile = createAsyncThunk('documents/postFile', async ({file, id}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'document')
    formData.append('refId', id)
    formData.append('field', "file")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)
    //console.log(response)
    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteImage = createAsyncThunk('documents/deleteImage', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        data: [],
        count: 1,
        params: {},
        document: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDocuments.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getDocumentsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleDocument.fulfilled, (state, action) => {
            state.document = action.payload.document
        })
    }
})

export default documentsSlice.reducer