import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getAdminForms = createAsyncThunk('adminForms/getAdminForms', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/administrator-forms`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleAdminForm = createAsyncThunk('adminForms/getSingleAdminForm', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/administrator-forms/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        adminForm : response.data
    }
})

export const editAdminForm = createAsyncThunk('adminForms/editAdminForm', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/administrator-forms/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const adminFormsSlice = createSlice({
    name: 'adminForms',
    initialState: {
        data: [],
        params: {},
        adminForm: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAdminForms.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSingleAdminForm.fulfilled, (state, action) => {
            state.adminForm = action.payload.adminForm
        })
    }
})

export default adminFormsSlice.reducer
