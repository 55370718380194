import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import kvet from "../../../../assets/images/frontend/kvet-1.png"
import dc from "../../../../assets/images/frontend/payment/logo_DC.jpg"
import discover from "../../../../assets/images/frontend/payment/logo_discover.jpg"
import maestro from "../../../../assets/images/frontend/payment/logo_maestro.gif"
import mc from "../../../../assets/images/frontend/payment/logo_mc.gif"
import visaElectron from "../../../../assets/images/frontend/payment/logo_visa_electron.gif"
import visa from "../../../../assets/images/frontend/payment/logo_visa.gif"
import {getDocuments} from "../../../modules/documents/store"
import toast from "react-hot-toast"
import Toast from "../../../components/toast/Toast"
import {useDispatch} from "react-redux"

function Footer() {
  const dispatch = useDispatch()
  const [documents, setDocuments] = useState([])
  const { t } = useTranslation()

  const fetchDataOnMount = async () => {
    const response = await dispatch(getDocuments({
      active: true
    }))
    if (response.error) {
      toast(ts => (
          <Toast t={ts} title={t("Problém!")} type={"danger"} message={t("Nastala chyba pri načítavaní dát! Skúste to neskôr")} />
      ))
    } else {
      //console.log(response.payload?.data)
      setDocuments(response.payload?.data)
    }
  }

  useEffect(() => {
    fetchDataOnMount()
  }, [])

  return (
    <>
      <footer className="footer-container">
        <div className="container">
          <div className="row footer-cta" data-aos="fade-up" data-aos-delay="200">
            <div className="footer-cta-33">
              <h1>{t("public.footer.text.1")}</h1>
              <p>{t("public.footer.text.2")}</p>
            </div>
            <div className="footer-cta-33 d-flex justify-content-center">
              <img src={kvet} />
            </div>
            <div className="footer-cta-33 text-end">
              <h1>{t("public.footer.text.3")}</h1>
              <p>{t("public.footer.text.4")}</p>
            </div>
          </div>
        </div>
        <div className="footer-frontend">
          <div className="container" data-aos="fade-up" data-aos-delay="200">
            <div className="row footer-contact">
              <div className="col-lg-4 col-md-4 footer-contact-item">
                <h3>{t("public.footer.links.contact.title")}</h3>
                <p>
                  Biofarma Kaška s.r.o. <br />
                  Vrbovce 883 <br />
                  Vrbovce 906 06
                </p>
              </div>
              <div className="col-lg-4 col-md-4 footer-contact-item">
                <ul>
                  <li><a href="mailto:predaj@biofarmakaska.sk">predaj@biofarmakaska.sk</a></li>
                  <li><a href="https://www.facebook.com/biofarmakaska" target="_blank">Facebook</a></li>
                  <li><a href="https://www.instagram.com/biofarma_kaska/" target="_blank">Instagram</a></li>
                  <li><a href="/login">ADMIN</a></li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 footer-contact-item">
                <ul>
                  <li><a href="/obchodne-podmienky">Všeobecné obchodné podmienky</a></li>
                  {documents?.length > 0 ? documents.map((doc) => (
                      <li key={doc.id}><a href={`${doc.file?.url}`} target="_blank">{doc.title}</a></li>
                  )) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-border" data-aos="fade-up" data-aos-delay="400">
            <div className="container">
              <div className="row footer-copyright">
                <div className="col-md-6">
                  <p>&copy; {new Date().getFullYear()} – Kaška. {t("public.footer.all.rights.reserved")}.</p>
                </div>
              <div class="col-md-6">
                <ul className="payment-badges">
                  <li><img src={dc} alt=""/></li>
                  <li><img src={discover} alt=""/></li>
                  <li><img src={maestro} alt=""/></li>
                  <li><img src={mc} alt=""/></li>
                  <li><img src={visaElectron} alt=""/></li>
                  <li><img src={visa} alt=""/></li>
                </ul>
              </div>
            </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
