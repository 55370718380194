import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const imageUpload = createAsyncThunk('blogArticles/imageUpload', async blobInfo => {
    const formData = new FormData()
    formData.append("files", blobInfo?.blob(), blobInfo?.filename())
    formData.append("data", JSON.stringify({}))

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getBlogArticles = createAsyncThunk('blogArticles/getBlogArticles', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-articles`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getBlogArticlesCount = createAsyncThunk('blogArticles/getBlogArticlesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-articles/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteBlogArticle = createAsyncThunk('blogArticles/deleteBlogArticle', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/blog-articles/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleBlogArticle = createAsyncThunk('blogArticles/getSingleBlogArticle', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog-articles/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        article : response.data
    }
})

export const postBlogArticle = createAsyncThunk('blogArticles/postBlogArticle', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/blog-articles`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editBlogArticle = createAsyncThunk('blogArticles/editBlogArticle', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/blog-articles/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFile = createAsyncThunk('blogArticles/postFile', async ({file, id}, {dispatch}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'blog-article')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }
    await dispatch(getSingleBlogArticle(id))

    return response
})

export const deleteImage = createAsyncThunk('blogArticles/deleteImage', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const blogArticlesSlice = createSlice({
    name: 'blogArticles',
    initialState: {
        data: [],
        count: 1,
        params: {},
        article: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBlogArticles.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getBlogArticlesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleBlogArticle.fulfilled, (state, action) => {
            state.article = action.payload.article
        })
    }
})

export default blogArticlesSlice.reducer