import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getHallDemands = createAsyncThunk('hallDemands/getHallDemands', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/hall-demands`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getHallDemandsCount = createAsyncThunk('hallDemands/getHallDemandsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/hall-demands/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleHallDemand = createAsyncThunk('hallDemands/getSingleHallDemand', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/hall-demands/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        hallDemand : response.data
    }
})

export const editHallDemand = createAsyncThunk('hallDemands/editHallDemand', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/hall-demands/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteHallDemand = createAsyncThunk('hallDemands/deleteHallDemand', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/hall-demands/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const hallDemandsSlice = createSlice({
    name: 'hallDemands',
    initialState: {
        data: [],
        count: 1,
        params: {},
        hallDemand: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getHallDemands.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getHallDemandsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleHallDemand.fulfilled, (state, action) => {
            state.hallDemand = action.payload.hallDemand
        })
    }
})

export default hallDemandsSlice.reducer