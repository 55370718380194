import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getAutomatedEmails = createAsyncThunk('adminForms/getAutomatedEmails', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/editable-automated-emails`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleAutomatedEmail = createAsyncThunk('adminForms/getSingleAutomatedEmail', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/editable-automated-emails/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        automatedEmail : response.data
    }
})

export const editAutomatedEmail = createAsyncThunk('adminForms/editAutomatedEmail', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/editable-automated-emails/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const automatedEmailsSlice = createSlice({
    name: 'automatedEmails',
    initialState: {
        data: [],
        params: {},
        automatedEmail: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAutomatedEmails.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSingleAutomatedEmail.fulfilled, (state, action) => {
            state.automatedEmail = action.payload.automatedEmail
        })
    }
})

export default automatedEmailsSlice.reducer
