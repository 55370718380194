// ** Icons Import
import {
    Home,
    FileText,
    User,
    CornerDownRight,
    ShoppingBag,
    Clipboard,
    File,
    Folder,
    Search,
    Image,
    Percent
} from 'react-feather'

export default [
    {
        id: 'accommodations',
        title: 'accommodation.menu',
        icon: <Home size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/accommodation-units'
    },
    {
        id: 'galleries',
        title: 'galleries.menu',
        icon: <Image size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/galleries'
    },
    {
        id: 'cms-pages',
        title: 'cms.pages.menu',
        icon: <File size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/cms-pages'
    },
    {
        id: 'halls',
        title: 'halls.menu',
        icon: <Home size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/halls'
    },
    {
        id: 'reservations',
        title: 'reservations.menu',
        icon: <FileText size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/reservations'
    },
    {
        id: 'documents',
        title: 'documents.menu',
        icon: <Folder size={20} />,
        navLink: '/documents',
        action: 'manage',
        roles: ['admin'],
        resource: 'all'
    },
    {
        id: 'services',
        title: 'services.menu',
        icon: <User size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        children : [
            {
                id: 'common-services-child',
                title: 'common.services.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/common-services',
                roles: ['admin'],
                resource: 'all'
            },
            {
                id: 'services-child',
                title: 'services.child.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/services',
                roles: ['admin'],
                resource: 'all'
            }
        ]
    },
    {
        id: 'coupons',
        title: 'coupons.menu',
        icon: <Percent size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/coupons'
    },
    {
        id: 'blog',
        title: 'blog.menu',
        icon: <Clipboard size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        children : [
            {
                id: 'blog-categories',
                title: 'blog.categories.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/blog-categories',
                roles: ['admin'],
                resource: 'all'
            },
            {
                id: 'blog-articles',
                title: 'blog.articles.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/blog-articles',
                roles: ['admin'],
                resource: 'all'
            }
        ]
    },
    {
        id: 'demands',
        title: 'demands.menu',
        icon: <File size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/demands'
    },
    {
        id: 'survey',
        title: 'survey.menu',
        icon: <Search size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        children : [
            {
                id: 'survey-questions',
                title: 'survey.questions.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/survey-questions',
                roles: ['admin'],
                resource: 'all'
            },
            {
                id: 'survey-requests',
                title: 'survey.requests.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/survey-requests',
                roles: ['admin'],
                resource: 'all'
            },
            {
                id: 'survey-answers',
                title: 'survey.answers.menu',
                icon: <CornerDownRight size={12} />,
                navLink: '/survey-answers',
                roles: ['admin'],
                resource: 'all'
            }
        ]
    },
    {
        id: 'seasonalOffers',
        title: 'seasonal.offers.menu',
        icon: <ShoppingBag size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/seasonal-offers'
    }
]