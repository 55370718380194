// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { initialRootAbility } from '../configs/acl/initialAbility'
import {getParsedJwtFromLocalStorage, getUserFromLocalJwt, setExpiration} from '../utility/Utils'
const config = useJwt.jwtConfig

const initialUser = () => {
  const user = getUserFromLocalJwt()
  return user ? user : {}
}

const initialAbility = () => {
  // OR load from localstorage - for example
  return initialRootAbility
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    ability: initialAbility()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload['userData']
      state.ability = action.payload['ability']
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      // localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
      if (action.payload['rememberMe'] === false) {
        const expirationTime = new Date()
        expirationTime.setHours(expirationTime.getHours() + 1) //login expiruje o 1 hodinu
        localStorage.setItem("expiration", JSON.stringify(expirationTime))
        setExpiration()
      } else {
        const jwtToken = getParsedJwtFromLocalStorage()
        const expirationTime = new Date(jwtToken.exp * 1000)
        localStorage.setItem("expiration", JSON.stringify(expirationTime))
        setExpiration()
      }
    },
    handleLogout: state => {
      state.userData = {}
      state.ability = null
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      // localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleUpdate: (state, action) => {
      state.userData = action.payload['userData']
      state.ability = action.payload['ability']
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      // localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    }
  }
})

export const { handleLogin, handleLogout, handleUpdate } = authSlice.actions

export default authSlice.reducer
