import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getSurveys = createAsyncThunk('surveys/getSurveys', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/surveys`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const editSurvey = createAsyncThunk('surveys/editSurvey', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/surveys/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSurveysCount = createAsyncThunk('surveys/getSurveysCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/surveys/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getUsers = createAsyncThunk('surveys/getUsers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleSurveyRequest = createAsyncThunk('surveys/getSingleSurveyRequest', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/surveys/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        survey : response.data
    }
})

export const deleteSurvey = createAsyncThunk('surveys/deleteSurvey', async survey => {
    if (survey) {
        for (const surveyAnswer of survey.survey_answers) {
            console.log(surveyAnswer)
            const response1 = await axios.delete(`${process.env.REACT_APP_API_URL}/survey-answers/${surveyAnswer.id}`)
            if (response1.error) {
                return null
            }
        }
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/surveys/${survey.id}`)

        if (response.status !== 200) {
            return null
        }

        return response
    } else {
        return null
    }

})

export const surveyRequestsSlice = createSlice({
    name: 'surveyRequests',
    initialState: {
        data: [],
        count: 1,
        params: {},
        survey: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSurveys.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSurveysCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleSurveyRequest.fulfilled, (state, action) => {
            state.survey = action.payload.survey
        })
    }
})

export default surveyRequestsSlice.reducer
