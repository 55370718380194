import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"


export const getHalls = createAsyncThunk('halls/getHalls', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/halls`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getHallsCount = createAsyncThunk('halls/getHallsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/halls/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleHall = createAsyncThunk('halls/getSingleHall', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/halls/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        hall : response.data
    }
})

export const deleteHall = createAsyncThunk('halls/deleteHall', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/halls/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postHall = createAsyncThunk('halls/postHall', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/halls`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editHall = createAsyncThunk('halls/editHall', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/halls/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteImage = createAsyncThunk('halls/deleteImage', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFile = createAsyncThunk('halls/postFile', async ({file, hall_id}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'hall')
    formData.append('refId', hall_id)
    formData.append('field', "images")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const hallsSlice = createSlice({
    name: 'halls',
    initialState: {
        data: [],
        count: 1,
        params: {},
        hall: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getHalls.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getHallsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleHall.fulfilled, (state, action) => {
            state.hall = action.payload.hall
        })
    }
})

export default hallsSlice.reducer