import React from "react"

export const renderContentByMark = (page, mark) => {
    const filterMark = page?.filter(x => x.mark === mark)
    return (<div dangerouslySetInnerHTML={{ __html: filterMark[0]?.content }} />)
}

export const renderTitleByMark = (page, mark) => {
    const filterMark = page?.filter(x => x.mark === mark)
    return filterMark[0]?.title
}

export const renderImageByMark = (page, mark) => {
    const filterMark = page?.filter(x => x.mark === mark)
    return filterMark[0]?.image?.url
}