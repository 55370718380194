// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'

import auth from './authentication'
import resetPassword from './resetPassword'
import accommodations from './../kaska/modules/accommodation-units/store'
import accommodationObjects from './../kaska/modules/accommodation-objects/store'
import reservations from './../kaska/modules/reservations/store'
import commonServices from './../kaska/modules/common-services/store'
import services from './../kaska/modules/services/store'
import documents from './../kaska/modules/documents/store'
import coupons from './../kaska/modules/coupons/store'
import blogArticles from './../kaska/modules/blog-articles/store'
import blogCategories from './../kaska/modules/blog-categories/store'
import halls from './../kaska/modules/halls/store'
import hallDemands from './../kaska/modules/demands/store'
import surveyQuestions from './../kaska/modules/survey/survey-questions/store'
import surveyAnswers from './../kaska/modules/survey/survey-answers/store'
import surveyRequests from './../kaska/modules/survey/survey-requests/store'
import dashboard from './../kaska/modules/dashboard/store'
import CMSPages from './../kaska/modules/cms-pages/store'
import Galleries from './../kaska/modules/galleries/store'
import SeasonalOffers from './../kaska/modules/seasonal-offers/store'
import ImageBlogArticles from './../kaska/modules/image-blog-articles/store'
import Activities from './../kaska/modules/activities/store'
import ActivityPrices from './../kaska/modules/activity-prices/store'
import AdminForms from './../kaska/modules/admin-forms/store'
import AutomatedEmails from './../kaska/modules/editable-automated-emails/store'
import Contacts from './../kaska/modules/contacts/store'

import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
  //kaska
  auth,
  resetPassword,
  accommodations,
  reservations,
  commonServices,
  services,
  documents,
  coupons,
  blogArticles,
  blogCategories,
  halls,
  hallDemands,
  surveyQuestions,
  surveyAnswers,
  surveyRequests,
  dashboard,
  CMSPages,
  Galleries,
  SeasonalOffers,
  ImageBlogArticles,
  accommodationObjects,
  Activities,
  ActivityPrices,
  AdminForms,
  AutomatedEmails,
  Contacts,

  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions
}

export default rootReducer
