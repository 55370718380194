// ** React Imports
import { lazy } from 'react'
import PriceList from '../../../../kaska/pages/frontend/PriceList'

const Landing = lazy(() => import('../../../../kaska/pages/frontend/Landing'))
const About = lazy(() => import('../../../../kaska/pages/frontend/About'))
const Accommodation = lazy(() => import('../../../../kaska/pages/frontend/Accommodation'))
//const ChalupaKaska = lazy(() => import('../../../../kaska/pages/frontend/ChalupaKaska'))
//const ApartmanLaska = lazy(() => import('../../../../kaska/pages/frontend/ApartmanLaska'))
//const ApartmanStastie = lazy(() => import('../../../../kaska/pages/frontend/ApartmanStastie'))
const Location = lazy(() => import('../../../../kaska/pages/frontend/Location'))
const Gallery = lazy(() => import('../../../../kaska/pages/frontend/Gallery'))
const Products = lazy(() => import('../../../../kaska/pages/frontend/Products'))
const Activities = lazy(() => import('../../../../kaska/pages/frontend/Activities'))
const Contact = lazy(() => import('../../../../kaska/pages/frontend/Contact'))
//const Blog = lazy(() => import('../../../../kaska/pages/frontend/Blog'))
//const BlogArticle = lazy(() => import('../../../../kaska/pages/frontend/BlogArticle'))
//const Post = lazy(() => import('../../../../kaska/pages/frontend/Post'))
const Reservation = lazy(() => import('../../../../kaska/pages/frontend/reservation/components/Reservation'))
const ReservationDetail = lazy(() => import('../../../../kaska/pages/frontend/reservation/components/ReservationDetail'))
//const Halls = lazy(() => import('../../../../kaska/pages/frontend/halls/components/Halls'))
//const HallForm = lazy(() => import('../../../../kaska/pages/frontend/halls/components/HallForm'))
//const AccommodationDetail = lazy(() => import('../../../../kaska/pages/frontend/accommodation/components/AccommodationDetail'))
const AccommodationObjectDetail = lazy(() => import('../../../../kaska/pages/frontend/accommodation/components/AccommodationObjectDetail'))
const Feedback = lazy(() => import('../../../../kaska/pages/frontend/feedback/components/Feedback'))
const PaymentConfirmation = lazy(() => import('../../../../kaska/pages/frontend/reservation/components/PaymentConfirmation'))
const SeasonalOffers = lazy(() => import('../../../../kaska/pages/frontend/SeasonalOffers'))
const GeneralTerms = lazy(() => import('../../../../kaska/pages/frontend/GeneralTerms'))
const Trips = lazy(() => import('../../../../kaska/pages/frontend/Trips'))

const FrontendRoutes = [
  {
    path: '/uvod',
    element: <Landing />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/obchodne-podmienky',
    element: <GeneralTerms />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/potvrdenie_platby',
    element: <PaymentConfirmation />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/o-nas',
    element: <About />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/vylety',
    element: <Trips />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/ubytovanie',
    element: <Accommodation />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  // {
  //   path: '/chalupa-kaska',
  //   element: <ChalupaKaska />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  // {
  //   path: '/apartman-laska',
  //   element: <ApartmanLaska />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  // {
  //   path: '/apartman-stastie',
  //   element: <ApartmanStastie />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  {
    path: '/lokalita',
    element: <Location />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/galeria',
    element: <Gallery />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/nasa-produkcia',
    element: <Products />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/aktivity',
    element: <Activities />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/kontakt',
    element: <Contact />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  // {
  //   path: '/blog',
  //   element: <Blog />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  // {
  //   path: '/blog/:slug',
  //   element: <BlogArticle />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  // {
  //   path: '/post',
  //   element: <Post />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  {
    path: '/rezervacia',
    element: <Reservation />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/rezervacia-detail/:slug',
    element: <ReservationDetail />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  // {
  //   path: '/konferencne-priestory',
  //   element: <Halls />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  // {
  //   path: '/rezervacia-konferencneho-priestoru/:id',
  //   element: <HallForm />,
  //   meta: {
  //     layout: 'frontend',
  //     publicRoute: true,
  //     restricted: false
  //   }
  // },
  {
    path: '/ubytovanie/:slug',
    element: <AccommodationObjectDetail />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/spatna-vazba/:id',
    element: <Feedback />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/sezonne-ponuky',
    element: <SeasonalOffers />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/cennik',
    element: <PriceList />,
    meta: {
      layout: 'frontend',
      publicRoute: true,
      restricted: false
    }
  }
]

export default FrontendRoutes
