import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getAnsweredSurveysCount = createAsyncThunk('dashboard/getAnsweredSurveysCount', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/surveys/count`, {
        params : {
            done : true,
            is_new : true
        }
    })

    if (response.status !== 200) {
        return null
    }

    return {
        answeredSurveysCount : response.data
    }
})

export const getNewHallDemandsCount = createAsyncThunk('dashboard/getNewHallDemandsCount', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/hall-demands/count`, {
        params : {
            is_new : true
        }
    })

    if (response.status !== 200) {
        return null
    }

    return {
        newHallDemandsCount : response.data
    }
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        answeredSurveysCount: null,
        newHallDemandsCount: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAnsweredSurveysCount.fulfilled, (state, action) => {
            state.answeredSurveysCount = action.payload.answeredSurveysCount
        }).addCase(getNewHallDemandsCount.fulfilled, (state, action) => {
            state.newHallDemandsCount = action.payload.newHallDemandsCount
        })
    }
})

export default dashboardSlice.reducer