import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getCMSPages = createAsyncThunk('CMSPages/getCMSPages', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/text-blocks`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getCMSPagesCount = createAsyncThunk('CMSPages/getCMSPagesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/text-blocks/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleCMSPage = createAsyncThunk('CMSPages/getSingleCMSPage', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/text-blocks/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        page : response.data
    }
})

export const editCMSPage = createAsyncThunk('CMSPages/editCMSPage', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/text-blocks/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteFile = createAsyncThunk('CMSPages/deleteFile', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/upload/files/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postImage = createAsyncThunk('CMSPages/postImage', async ({file, id}, {dispatch}) => {
    const formData = new FormData()
    formData.append("files", file)
    formData.append('ref', 'text-block')
    formData.append('refId', id)
    formData.append('field', "image")

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData)

    if (response.status !== 200) {
        return null
    }
    await dispatch(getSingleCMSPage(id))

    return response
})

export const CMSPagesSlice = createSlice({
    name: 'CMSPages',
    initialState: {
        data: [],
        count: 1,
        params: {},
        page: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCMSPages.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getCMSPagesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleCMSPage.fulfilled, (state, action) => {
            state.page = action.payload.page
        })
    }
})

export default CMSPagesSlice.reducer