import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getReservations = createAsyncThunk('reservations/getReservations', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/reservations`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSingleReservation = createAsyncThunk('reservations/getSingleReservation', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/reservations/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response.data
})

export const deleteReservation = createAsyncThunk('reservations/deleteReservation', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/reservations/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteTransactionLog = createAsyncThunk('reservations/deleteTransactionLog', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/transaction-logs/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteServiceReservation = createAsyncThunk('reservations/deleteServiceReservation', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/service-reservations/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteCommonServiceReservation = createAsyncThunk('reservations/deleteCommonServiceReservation', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/common-service-reservations/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const sendRemovedServiceNotification = createAsyncThunk('reservations/sendRemovedServiceNotification', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-removed-service-notification`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postReservation = createAsyncThunk('reservations/postReservation', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reservations`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editReservation = createAsyncThunk('reservations/editReservation', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/reservations/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getReservationsCount = createAsyncThunk('reservations/getReservationsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/reservations/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getUsers = createAsyncThunk('reservations/getUsers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const createNewUser = createAsyncThunk('reservations/createNewUser', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/createNewUser`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const sendEmailConfirmation = createAsyncThunk('reservations/sendEmailConfirmation', async id => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-reservation-confirmation`, {
        reservationId : id
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const sendEmailOnStateChange = createAsyncThunk('reservations/sendEmailOnStateChange', async ({id, fromState, toState}) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-reservation-state-change`, {
        reservationId : id,
        fromState,
        toState
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getIpAddress = createAsyncThunk('reservations/getIpAddress', async () => {
    const response = await fetch(`https://api.ipify.org/?format=json`).then((response) => response.json())

    if (!response) {
        return null
    }

    return response
})

export const reservationSlice = createSlice({
    name: 'reservations',
    initialState: {
        data: [],
        count: 1,
        params: {},
        reservation : null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getReservations.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getReservationsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleReservation.fulfilled, (state, action) => {
            state.reservation = action.payload
        })
    }
})

export default reservationSlice.reducer
