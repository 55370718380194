import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"


export const getSurveyQuestions = createAsyncThunk('surveyQuestions/getSurveyQuestions', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-questions`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSurveyQuestionsCount = createAsyncThunk('surveyQuestions/getSurveyQuestionsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-questions/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleSurveyQuestion = createAsyncThunk('surveyQuestions/getSingleSurveyQuestion', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-questions/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        question : response.data
    }
})

export const deleteSurveyQuestion = createAsyncThunk('surveyQuestions/deleteSurveyQuestion', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/survey-questions/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postSurveyQuestion = createAsyncThunk('surveyQuestions/postSurveyQuestion', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/survey-questions`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editSurveyQuestion = createAsyncThunk('surveyQuestions/editSurveyQuestion', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/survey-questions/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const surveyQuestionsSlice = createSlice({
    name: 'surveyQuestions',
    initialState: {
        data: [],
        count: 1,
        params: {},
        question: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSurveyQuestions.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSurveyQuestionsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleSurveyQuestion.fulfilled, (state, action) => {
            state.question = action.payload.question
        })
    }
})

export default surveyQuestionsSlice.reducer