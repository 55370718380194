import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { AlertCircle, X } from 'react-feather'

const Toast = ({ t, title, type, message }) => {
    return (
      <div className='d-flex'>
        <div className='me-1'>
          <Avatar size='sm' color={type} icon={<AlertCircle size={12} />} />
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <h6>{title}</h6>
            <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
          </div>
          <span>{message}</span>
        </div>
      </div>
    )
}
export default Toast