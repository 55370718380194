import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getActivityPrices = createAsyncThunk('activityPrices/getActivityPrices', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activity-prices`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getActivityPricesCount = createAsyncThunk('activityPrices/getActivityPricesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activity-prices/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const deleteActivityPrice = createAsyncThunk('activityPrices/deleteActivityPrice', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/activity-prices/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getSingleActivityPrice = createAsyncThunk('activityPrices/getSingleActivityPrice', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/activity-prices/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        activityPrice : response.data
    }
})

export const postActivityPrice = createAsyncThunk('activityPrices/postActivityPrice', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity-prices`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editActivityPrice = createAsyncThunk('activityPrices/editActivityPrice', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/activity-prices/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const activityPricesSlice = createSlice({
    name: 'activityPrices',
    initialState: {
        data: [],
        count: 1,
        params: {},
        activityPrice: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getActivityPrices.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getActivityPricesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleActivityPrice.fulfilled, (state, action) => {
            state.activityPrice = action.payload.activityPrice
        })
    }
})

export default activityPricesSlice.reducer