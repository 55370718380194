import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"


export const getCommonServices = createAsyncThunk('commonServices/getCommonServices', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/common-services`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getCommonServicesCount = createAsyncThunk('commonServices/getCommonServicesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/common-services/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleCommonService = createAsyncThunk('commonServices/getSingleCommonService', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/common-services/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        commonService : response.data
    }
})

export const deleteCommonService = createAsyncThunk('commonServices/deleteCommonService', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/common-services/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postCommonService = createAsyncThunk('commonServices/postCommonService', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/common-services`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editCommonService = createAsyncThunk('commonServices/editCommonService', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/common-services/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const commonServicesSlice = createSlice({
    name: 'commonServices',
    initialState: {
        data: [],
        count: 1,
        params: {},
        commonService: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCommonServices.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getCommonServicesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleCommonService.fulfilled, (state, action) => {
            state.commonService = action.payload.commonService
        })
    }
})

export default commonServicesSlice.reducer