import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getSurveyAnswers = createAsyncThunk('surveyAnswers/getSurveyAnswers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-answers`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getSurveyAnswersCount = createAsyncThunk('surveyAnswers/getSurveyAnswersCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-answers/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleSurveyAnswer = createAsyncThunk('surveyAnswers/getSingleSurveyQuestion', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey-answers/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        answer : response.data
    }
})

export const surveyAnswersSlice = createSlice({
    name: 'surveyAnswers',
    initialState: {
        data: [],
        count: 1,
        params: {},
        answer: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSurveyAnswers.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getSurveyAnswersCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleSurveyAnswer.fulfilled, (state, action) => {
            state.answer = action.payload.answer
        })
    }
})

export default surveyAnswersSlice.reducer